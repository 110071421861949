
import {defineComponent} from "vue";
import {GoogleMap, Marker} from "vue3-google-map";

export default defineComponent({
  name: 'Contact',
  components: {
    Marker, GoogleMap

  },
  mounted() {
    window.scrollTo(0, 0);
    let projectLink = document.getElementById('projectLink');
    projectLink!.classList.remove('router-link-active','router-link-exact-active');
  },
  data() {
    return {
      center: {lat: 50.957337173795594, lng: 3.6310414558671007},
      markerPos:{lat: 100.957337173795594, lng: 5.6310414558671007},
      markers: [
        {
          position: {
            lat: 50.957337173795594, lng: 3.6310414558671007
          }
        }
      ]
    }
  }
});
